<template>
    <div class="datas w-1400">
        <div class="tab">
            <div class="teb-item" @click="showS = 1" :class="{act:showS === 1}">乡宁荟交易数据统计</div>
            <div class="teb-item" @click="showS = 2" :class="{act:showS === 2}">乡宁直聘趋势统计</div>
            <div class="teb-item" @click="showS = 3" :class="{act:showS === 3}">兴乡企业数据统计</div>
            <div class="teb-item" @click="showS = 4" :class="{act:showS === 4}">资源集市交易数据统计</div>
        </div>
        <div v-if="showS === 1">
            <div id="data-chart1">

            </div>
            <div class="chart2">
                <el-row :gutter="50">
                    <el-col :span="8">
                        <div id="pie">
                        </div>
                    </el-col>
                    <el-col :span="16">
                        <div class="chart2-h">项目统计</div>
                        <table class="xm-tabel">
                            <tr>
                                <th>项目</th>
                                <th>累计发布量</th>
                                <th>当前有效数量</th>
                                <th>浏览量</th>
                                <th>好评量</th>
                            </tr>
                            <tr v-for="(item,k) in peojectData" :key="k+'project'">
                                <td>{{item.name}}</td>
                                <td>{{item.totalNumber}}</td>
                                <td>{{item.validNumber}}</td>
                                <td>{{item.viewNumber}}</td>
                                <td>{{item.goodNumber}}</td>
                            </tr>
                        </table>
                    </el-col>
                </el-row>
            </div>
        </div>
        <xnzp v-if="showS === 2"></xnzp>
        <xxqy v-if="showS === 3"></xxqy>
        <zyjs v-if="showS === 4"></zyjs>
    </div>
</template>
<script>
import {GetStatVillage,GetScenerySpot,GetStatProject} from '@/api/other'
import xnzp from './dataComponents/xnzpTj.vue'
import xxqy from './dataComponents/xxqyTj.vue'
import zyjs from './dataComponents/zyjsTj.vue'
export default {
    components:{xnzp,xxqy,zyjs},
    data(){
        return{
            villageData:[],
            SceneryData:[],
            peojectData:[],
            showS:1
        }
    },
    watch:{
        showS(a){
            if(a === 1){
                this.getSceneryData()
                this.getVillageData()
                this.getProject()
            }
        }
    },
    mounted(){
        if( this.showS === 1){
            this.getSceneryData()
            this.getVillageData()
            this.getProject()
        }
        
    },
    methods:{
        drowChart1(){
            let option = {
                title:{
                    text:'景点浏览排行榜',
                    bottom:10
                },
                tooltip: {
                    trigger: 'axis',
                    // axisPointer: {
                    //     type: 'shadow'
                    // }
                },
                legend: {
                    data: this.SceneryData.map(item=>item.name),
                    top:10
                },
                xAxis: [
                    {
                        type: 'category',
                        axisTick: { show: false },
                        data: this.SceneryData[0]&&this.SceneryData[0].dataList.map(item=>item.yearMonth)
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: 
                    this.SceneryData.map(item=>{
                        return{
                            name: item.name,
                            smooth: true,
                            type: 'line',
                            emphasis: {
                                focus: 'series'
                            },
                            data:item.dataList.map(v=>v.number)
                        }
                    })
            };
            let myEchart = this.echart.init(document.getElementById('data-chart1'))
            myEchart.setOption(option);
        },
        drowChart2(){
            let option = {
                title:{
                    text:'美丽乡村区划占比',
                    left:'center',
                    top:10
                },
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    bottom: '5%',
                    left: 'center'
                },
                series: [
                    {
                    name: '美丽乡村',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: '#fff',
                        borderWidth: 2
                    },
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                        show: true,
                        fontSize: '40',
                        fontWeight: 'bold'
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: this.villageData.map(item=>{return{value:item.number,name:item.regionName}})
                    
                    }
                ]
            };
            let myechart = this.echart.init(document.getElementById('pie'));
            myechart.setOption(option);
        },
        getVillageData(){
            GetStatVillage().then(res=>{
                this.villageData = res.data.data;
                this.drowChart2();
            })
        },
        getSceneryData(){
            GetScenerySpot().then(res=>{
                this.SceneryData = res.data.data;
                this.drowChart1();
            })
        },
        getProject(){
            GetStatProject().then(res=>{
                this.peojectData = res.data.data;
            })
        }
    }
}
</script>
<style scoped>
.xm-tabel tr~tr:hover{
    background: #fff;
    color: #0e6ac1;
}
.xm-tabel tr~tr{
    background: #eee;
    border: 3px solid #fff;
    border-radius: 10px;
}
.xm-tabel tr{
    height: 75px;
}
.xm-tabel th{
    text-align: center;
}
.xm-tabel{
    width: 100%;
    text-align: center;
    font-size: 16px;
}
.chart2-h{
    font-size: 16px;
    font-weight: 600;
    line-height: 30px;
}
#pie{
    height: 480px;
    background: #eee;
}
.chart2{
    margin-top: 40px;
}
#data-chart1{
    height: 610px;
    width: 100%;
    margin-top: 40px;
    background: #eee;
}
.teb-item:hover,.teb-item.act{
    background: #f37b1c;
    color:#fff
}
.teb-item{
    padding:10px 20px;
    font-size: 24px;
    background: #ddd;
    cursor: pointer;
}
.tab{
    display: flex;
    justify-content: space-between;
}
.datas{
    font-family: '微软雅黑';
}
</style>