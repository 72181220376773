<template>
    <div>
        <div id="data-chart1">

        </div>
    </div>
</template>

<script>
import { GetStatEnterprise } from '@/api/other'
export default {
    data(){
        return{
            SceneryData:[],
        }
    },
    methods:{
        drowChart1(){
            let option = {
                title:{
                    text:'各区域兴乡企业数量',
                    top:10
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                // legend: {
                //     data: this.SceneryData.map(item=>item.industry),
                //     top:10
                // },
                xAxis: [
                    {
                        type: 'category',
                        axisTick: { show: false },
                        data: this.SceneryData.map(item=>item.regionName)
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: {
                    name: '数量',
                    type: 'bar',
                    emphasis: {
                        focus: 'series'
                    },
                    data:this.SceneryData.map(item=>item.number)
                }
            };
            let myEchart = this.echart.init(document.getElementById('data-chart1'))
            myEchart.setOption(option);
        },
        getPageList(){
            GetStatEnterprise().then(res=>{
                this.SceneryData = res.data.data;
                this.drowChart1();
            })
        }
    },
    mounted(){
        this.getPageList();
    }
}
</script>

<style scoped>
#data-chart1{
    height: 610px;
    width: 100%;
    margin-top: 40px;
    background: #eee;
}
</style>