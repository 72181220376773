<template>
    <div>
        <div id="data-chart1">

        </div>
    </div>
</template>

<script>
import { GetXnRecruit } from '@/api/other'
export default {
    data(){
        return{
            SceneryData:[],
        }
    },
    methods:{
        drowChart1(){
            let option = {
                title:{
                    text:'乡宁直聘趋势统计',
                    top:10
                },
                tooltip: {
                    trigger: 'axis',
                    // axisPointer: {
                    //     type: 'shadow'
                    // }
                },
                legend: {
                    data: this.SceneryData.map(item=>item.industry),
                    top:10
                },
                xAxis: [
                    {
                        type: 'category',
                        axisTick: { show: false },
                        data: this.SceneryData[0]&&this.SceneryData[0].dataList.map(item=>item.yearMonth)
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: 
                    this.SceneryData.map(item=>{
                        return{
                            name: item.industry,
                            type: 'line',
                            smooth: true,
                            emphasis: {
                                focus: 'series'
                            },
                            data:item.dataList.map(v=>v.number)
                        }
                    })
            };
            let myEchart = this.echart.init(document.getElementById('data-chart1'))
            myEchart.setOption(option);
        },
        getPageList(){
            GetXnRecruit().then(res=>{
                this.SceneryData = res.data.data;
                this.drowChart1();
            })
        }
    },
    mounted(){
        this.getPageList();
    }
}
</script>

<style scoped>
#data-chart1{
    height: 610px;
    width: 100%;
    margin-top: 40px;
    background: #eee;
}
</style>